import {APPLE_PLAY_STORE, BECOME_DRIVER, GOOGLE_PLAY_STORE} from "../utils/links";

function BecomeDriver() {
    return (
        <>
            <div className="home-banner-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="banner-content">
                                <h1 className="extrabold text-white">
                                    Drivers love us.<br/> They really do!
                                </h1>
                                <p className="text-white">
                                    We recognize that drivers are essential to our success. That’s why we’ve designed
                                    our services to ensure drivers consistently receive numerous deliveries, maximizing
                                    their earnings efficiently and in less time.
                                </p>
                                <div className="banner-btn-div">
                                    <a href={BECOME_DRIVER} className="btn btn-primary">
                                        Become a Driver
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="who-it-work-div hiw-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hiw-tile text-center">
                                <h1 className="text-primary">
                                    How it works
                                </h1>
                                <p className="text-primary">
                                    As a Flexio Driver, you become part of an innovative community that values
                                    efficiency and opportunity. We welcome a variety of vehicles, including trucks,
                                    vans, SUVs, and cars. Simply register on the Flexio Driver app and start earning at
                                    your own pace. Embrace the flexibility and efficiency that comes with being a Flexio
                                    Driver and join us in revolutionizing the delivery industry.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="hiw-card-list">
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-4">
                                        <div className="feature-card">
                                            <div className="fc-icon">
                                                <img src="/img/clock-icon.svg" alt="icon" className="img-fluid"/>
                                            </div>
                                            <div className="fc-content">
                                                <h5 className="text-primary">Safe & Reliable Delivery Jobs</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-4">
                                        <div className="feature-card">
                                            <div className="fc-icon">
                                                <img src="/img/clock-icon.svg" alt="icon" className="img-fluid"/>
                                            </div>
                                            <div className="fc-content">
                                                <h5 className="text-primary">Community Support
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-4">
                                        <div className="feature-card">
                                            <div className="fc-icon">
                                                <img src="/img/clock-icon.svg" alt="icon" className="img-fluid"/>
                                            </div>
                                            <div className="fc-content">
                                                <h5 className="text-primary">Manage your Schedule
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-4">
                                        <div className="feature-card">
                                            <div className="fc-icon">
                                                <img src="/img/clock-icon.svg" alt="icon" className="img-fluid"/>
                                            </div>
                                            <div className="fc-content">
                                                <h5 className="text-primary">Less Stress, Better Life
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-4">
                                        <div className="feature-card">
                                            <div className="fc-icon">
                                                <img src="/img/clock-icon.svg" alt="icon" className="img-fluid"/>
                                            </div>
                                            <div className="fc-content">
                                                <h5 className="text-primary">Access to Optimized Routes</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-4">
                                        <div className="feature-card">
                                            <div className="fc-icon">
                                                <img src="/img/clock-icon.svg" alt="icon" className="img-fluid"/>
                                            </div>
                                            <div className="fc-content">
                                                <h5 className="text-primary">Competitive Earnings
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="what-our-driver-div bg-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="wod-content">
                                <h1 className="text-white">
                                    What our drivers<br/> are saying
                                </h1>
                                <p className="text-white">
                                    At Flexio, we prioritize the well-being of our drivers by ensuring they have access
                                    to numerous deliveries, efficient routes, and flexible schedules that fit their
                                    lifestyles. We provide ongoing support and resources to help drivers succeed and
                                    feel valued. Don’t just take our word for it—read what other drivers are saying
                                    about working with Flexio and
                                    <b>discover how we make driver satisfaction a top priority.</b>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="our-driver-slider">
                                <div className="driver-say-carousel owl-carousel owl-theme">
                                    <div className="item">
                                        <div className="ods-card">
                                            <div className="ods-img">
                                                <img src="/img/dr1.png" alt="img" className="img-fluid"/>
                                            </div>
                                            <div className="ods-content">
                                                <p>
                                                    Flexio has been a tremendous help during a crucial time in my life,
                                                    providing me with the flexibility needed to manage my schedule and
                                                    care for my family. I especially value the opportunity to interact
                                                    with clients
                                                </p>
                                                <h6>
                                                    Ana Massanet
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-app-div bg-primary2 py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mobile-screen">
                                <img src="/img/mobile-screen.png" alt="img" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="app-content">
                                <h1>
                                    Manage your deliveries through our app
                                </h1>
                                <p>
                                    The Flexio Driver app provides the easiest way to manage your jobs and have
                                    visibility of everything you are doing.
                                </p>
                                <ul className="app-point-ul">
                                    <li><b>Jobs Listing:</b> View all available jobs nearby and choose your preferred
                                        ones.
                                    </li>
                                    <li><b>My Jobs:</b> See all accepted jobs up to seven days in advance.</li>
                                    <li><b>Active Jobs:</b> Track open or in-progress jobs.</li>
                                    <li><b>Delivery History & My Earnings:</b> Monitor your earnings and job details.
                                    </li>
                                    <li><b>My Account:</b> Modify options, including adding more vehicles.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="get-started-div bg-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="get-started-title">
                                <h6 className="text-primary2">
                                    GET STARTED
                                </h6>
                                <h1>
                                    Start earning today!
                                </h1>
                            </div>
                            <div className="get-started-btn">
                                <div>
                                    <a href={APPLE_PLAY_STORE}><img src="/img/ios.png" alt="ios" className="img-fluid"/></a>
                                </div>
                                <div>
                                    <a href={GOOGLE_PLAY_STORE}>
                                        <img src="/img/google.png" alt="ios"
                                             className="img-fluid"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-start">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="get-started-title mb-5 pb-0 pb-sm-0 pb-md-3">
                                <h1>
                                    Start earning today!
                                </h1>

                            </div>
                            <div className="faq-box">
                                <div className="faq-img">
                                    <img src="/img/faq1.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="faq-content">
                                    <h2 className="text-white">
                                        How are miles and time calculated on deliveries/routes?
                                    </h2>
                                    <p className="text-white">
                                        The mileage traveled is calculated through a digital service called Google Maps
                                        at the time of providing an estimate to the client where he considers the
                                        fastest route to reach the destinations. The time on the other hand is
                                        calculated through the Flexio Drivers mobile application which provides the real
                                        duration of each route or waiting time at each point.
                                    </p>
                                </div>
                            </div>

                            <div className="faq-box">
                                <div className="faq-img">
                                    <img src="/img/faq2.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="faq-content">
                                    <h2 className="text-white">
                                        When are completed deliveries or routes charged?
                                    </h2>
                                    <p className="text-white">
                                        Flexio's pay cycle runs from Monday to Sunday. Weekly, every Monday, direct
                                        deposits are processed to the bank account you added on the form when you signed
                                        in to Flexio. The payment is reflected in your account typically on Wednesdays,
                                        although depending on your bank it may take an additional day.
                                    </p>
                                </div>
                            </div>

                            <div className="faq-box">
                                <div className="faq-img">
                                    <img src="/img/faq3.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="faq-content">
                                    <h2 className="text-white">
                                        How can drivers verify their payment?
                                    </h2>
                                    <p className="text-white">
                                        In the same Flexio Drivers mobile application by pressing the "Wallet" button
                                        you can see all the deliveries or routes that you have worked. The price of each
                                        delivery or route does not reflect the adjustments for retention of services
                                        rendered to be paid to the Department of the Treasury or other specific expenses
                                        that may apply, such as the cost of liability insurance.
                                    </p>
                                </div>
                            </div>

                            <div className="faq-box">
                                <div className="faq-img">
                                    <img src="/img/faq4.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="faq-content">
                                    <h2 className="text-white">
                                        What is Flexio's incentive plan?
                                    </h2>
                                    <p className="text-white">
                                        Flexio applies an Incentive Plan for the Pharmacy delivery service to Customers
                                        where they are paid when they are assigned fewer orders per route than promised
                                        on average and are also paid in the event that the distances traveled are
                                        greater than planned. This ensures that they earn what is necessary for the job
                                        to be monetarily attractive, compared to other services such as Uber, UVA,
                                        Doordash, Uber Eats, and other distribution companies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BecomeDriver;