import React from "react";
import {useNavigate} from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <main className="d-flex min-vh-100 align-items-center justify-content-center bg-white px-3 py-5">
            <div className="text-center">
                <p className="fs-5 fw-semibold text-primary">404</p>
                <h1 className="mt-4 display-4 fw-bold text-dark">
                    Page not found
                </h1>
                <p className="mt-4 fs-5 text-secondary">
                    Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div className="mt-5 d-flex align-items-center justify-content-center gap-3">
                    <button onClick={() => navigate('/')}
                            className="btn btn-primary px-4 py-2">
                        Go back home
                    </button>
                </div>
            </div>
        </main>
    );
};

export default ErrorPage;
