import React from "react";
import {useNavigate} from "react-router-dom";

const ClienttermsPage = () => {
    const navigate = useNavigate();

    return (
        <div className="container my-5 privacy-page">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <br/><br/><br/>

            <h1 className="text-center mb-4">Client Terms</h1>
            <h2 className="text-center mb-4">
        FLEXIO INTERNATIONAL, LLC<br></br>TECHNOLOGY SERVICE AGREEMENT
      </h2>
      <p>
        This Technology Services Agreement (“Agreement”) is a legally binding
        agreement signed between Flexio International LLC, (“FLEXIO”), a limited
        liability company duly organized under the laws of the Commonwealth of
        Puerto Rico, and the Sender (“Sender and/or you”). This Agreement is a
        part of all general Flexio Terms and Conditions (“Terms”) and is
        incorporated therein by this reference. Any capitalized terms used
        herein and not otherwise defined shall have the same definition as set
        forth in the Terms.
      </p>
      <p>
        <strong>WITNESSETH</strong>
      </p>
      <p>
        <strong>WHEREAS FLEXIO</strong> provides resource management/routing
        technology and lead generation transportation service to Sender using
        the FLEXIO Services. The FLEXIO Services enables Sender, through a
        proprietary platform, to utilize an authorized transportation provider
        to seek, receive and fulfill requests for item transportation services
        (including, relocation, moving and/or messengering) (collectively the
        “Transportation Services”).
      </p>
      <p>
        <strong>WHEREAS</strong> in order to use FLEXIO Services,{" "}
        <strong>Sender</strong> agrees to the terms and conditions that are set
        forth herein. Upon execution of this Agreement (be it, electronically or
        manually), <strong>Sender</strong> and <strong>FLEXIO</strong> shall be
        bound by the terms and conditions set forth herein.
      </p>
      <h2 className="text-center mb-4">TERMS AND CONDITIONS</h2>
      <p>
        <strong>1. Use of Flexio Services</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>1.1</strong> These Terms and Conditions (“T&Cs”) set forth the
          terms and conditions that you (“You,” “Your,” “Yourself” or “User”)
          must accept and agree to if you want to download, install, access, or
          use the Flexio website platform, whether via our website at
          app.goflexio.com (“Website”), or API, or the Driver’s Flexio
          Application (“Flexio Driver App”), (collectively, the “Flexio
          Platform” or the “Platform”). These T&Cs, including those referenced
          herein, constitute a legal agreement between you and Flexio Inc. By
          accessing or using the Flexio Platform in any manner, you are
          acknowledging that you have read and understand these T&Cs and
          agreeing to be bound by them.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>1.2</strong> As used in these T&Cs, the word “Sender” refers
          to any entity or individual who forms an account on the Flexio App
          and/or who has a written agreement with Flexio to access and use the
          Flexio Platform to crowdsource independent service providers to
          perform delivery services (“Delivery Services”), or for whose benefit
          Delivery Services are being performed. Similarly, a “Driver” is any
          entity or individual who forms an account on the Flexio App as a
          Driver and/or otherwise performs Delivery Services, including at the
          request of, or for the benefit of, a Sender. The time period in which
          Delivery Services are performed by a Driver, as well as the item(s) of
          personal property and goods being picked up and/or delivered by a
          Driver, are collectively referred to herein as a “Job”. A “Job” is
          also a pre-scheduled amount of time to deliver Stops from a Sender for
          guaranteed earnings. An “Earnings Guarantee” is the minimum amount
          you’ll earn in exchange for each Job. A “recipient” is any entity or
          individual who receives a Stop or items contained in a Stop. All Users
          of the Flexio Platform, including Drivers and Senders, are referred to
          collectively as “Users” herein.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>1.3</strong> Flexio reserves the right to modify these T&Cs or
          any of our policies relating to our Platform at any time, effective
          upon posting of an updated version of the T&Cs on our Website and/or
          on our App. You are responsible for regularly reviewing any updates to
          the T&Cs. Accessing or using the Flexio Platform after any updates to
          these T&Cs shall constitute Your consent to all changes made to the
          T&Cs in such update and Your affirmation of Your agreement to the
          updated T&Cs as a whole, whether or not Flexio provides You prior
          notice of such modifications.
        </p>
      </p>
      <p>
        <strong>2. The Flexio Platform</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>2.1</strong> Our Platform provides Senders with self-enabled
          logistics services via one or more of Flexio’s proprietary Apps,
          including the ability to crowdsource independent service providers to
          provide Delivery Services and to receive electronic real-time tracking
          and photographic proof of pickup and delivery; to access
          individualized account dashboards to schedule and manage Jobs; to
          upload delivery orders in bulk to be batched into consolidated Jobs
          using Flexio’s proprietary algorithm; and to sort multiple Jobs into
          batches using Flexio Services.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>2.2</strong> The Flexio Platform also enables Drivers to
          receive notifications of, and access to, requests placed by Senders on
          the Platform for Delivery Services, and the ability to offer to
          perform those Delivery Services and/or schedule Jobs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>2.3</strong> Flexio provides Flexio Services and Drivers
          provide Delivery Services. Flexio’s business of developing and
          operating a logistics management platform is separate and distinct
          from a Driver’s business of providing Delivery Services. However, as
          the context requires, the Flexio Services and the Delivery Services
          may be collectively referred to herein as the “Services.”
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>2.4</strong> Flexio is a logistics management platform and
          does not provide delivery or transportation services. Flexio is not a
          motor carrier.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>2.5</strong> Drivers are independent service providers who
          choose to use the app in order to receive access to Senders’ requests
          for delivery services and do not perform delivery services for Flexio.
        </p>
      </p>
      <p>
        <strong>
          3. Terms applicable to all parties - User Agreements, Representations
          and Warranties:
        </strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.1</strong> Every time you access or use the Flexio Platform
          or receive or perform services, you expressly agree, represent, and
          warrant that, at the time of each such access or use, you are legally
          entitled to and have the right, authority, and capacity to enter into
          the agreements set forth in these T&Cs and to fulfill your obligations
          herein.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.2</strong> Your use of our platform is for your sole use,
          and you will not resell it to a third party.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.3</strong> You will comply with these T&Cs, and all federal,
          state, and local laws and regulations, rules, and orders by any
          governmental authority with respect to your use of our platform, to
          the extent legally applicable to you (“Applicable Law”).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.4</strong> You will only create and maintain one account,
          although a Sender may have several users.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.5</strong> You will keep secure and confidential your user
          account password or any other keys or identification which allows you
          to access our Platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.6</strong> You will provide Flexio with such proof of
          identity we reasonably request and will not impersonate any person or
          entity or falsify or otherwise misrepresent yourself or your
          affiliation (actively or by omission) with any person or entity.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.7</strong> You will not represent yourself to be an agent,
          subcontractor, representative, employee, or affiliate of Flexio, or
          any affiliate of Flexio.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.8</strong> You will only access and use our platform for
          lawful purposes and in accordance with all applicable law and will not
          use our platform to promote or encourage any illegal activity, or to
          commit or assist in the commission of a crime.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.9</strong> You will keep in strict confidence and shall not
          access, disclose, or use for the benefit of yourself or others, any
          and all third parties’ confidential information (as defined below),
          except in each instance, only as strictly necessary to receive or
          perform services as intended or carry out and perform your obligations
          under these T&Cs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.10</strong> You will not collect or store any information,
          including confidential information, about any Flexio personnel, any
          other user (or their personnel), or any Stop recipient (or their
          personnel), except as specifically required to receive or perform
          services as intended or carry out and perform your obligations under
          these T&Cs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.11</strong> You will not contact any Flexio personnel, any
          other User (or their personnel), or any Stop recipient (or their
          personnel) directly or outside of the platform or once the Stop is
          complete unless strictly necessary to receive or perform services as
          intended or carry out and perform your obligations under these T&Cs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.12</strong> You will not copy, or distribute text, graphics,
          images, music, software, audio, video, information, trademarks, logos,
          or other like materials related to our business or platform unless
          specifically allowed by these T&Cs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.13</strong> You will not access or use our platform in any
          way that could cause harm, nuisance, annoyance, or inconvenience, or
          interfere with or negatively affect other users from fully using or
          enjoying our platform as intended.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.14</strong> You will not infringe the rights of any third
          party (including other users and Flexio), including without
          limitation, their intellectual property, privacy, publicity, or
          contractual rights.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>3.15</strong> You will not assist any third party in any of
          the above.
        </p>
      </p>
      <p>
        <strong>4. Non-Discrimination Policy</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>4.1</strong> You agree that you will not discriminate against
          any user, Flexio personnel, recipient, or other third party based on
          race, religion, national origin, disability, sexual orientation, sex,
          marital status, gender identity, age or any other characteristic
          protected under applicable law. All users should feel safe and welcome
          and Flexio does not tolerate any discriminatory conduct from users.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>4.2</strong> Examples of discriminatory conduct include, but
          are not limited to, intentionally canceling Jobs solely for the
          purpose of avoiding giving your Job over to a particular person, or
          avoiding a particular neighborhood, due to the characteristics of the
          people or businesses that are located in that area, or because you are
          uncomfortable with giving your Job over to certain persons based on
          traits such as age, color, disability, gender identity, marital
          status, national origin, race, religion, sex, or sexual orientation.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>4.3</strong> Engaging in discriminatory conduct may lead to
          your deactivation from the Flexio platform.
        </p>
      </p>
      <p>
        <strong>5. Respect Others Policy</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.1</strong> You agree that you will not stalk, intimidate,
          threaten, harass, cause physical or mental distress, or otherwise
          display any aggressive, confrontational, intimidating, threatening or
          harassing behavior toward or against any user, Flexio personnel,
          recipient, or other third party, including without limitation.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.2</strong> Hitting, hurting, or intending to hurt any person
          or animal, or threatening to do the same.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.3</strong> Using language or making gestures that could be
          disrespectful, harassing or threatening.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.4</strong> Assault, harassment, and misconduct, including
          sexual, of any kind. Assault includes any intentional bodily contact
          made without explicit consent of the other person, and sexual
          harassment and misconduct, includes without limitation, unconsented to
          advances, behaviors and remarks like nudging, whistling, winking, or
          flirting, discussing your own or someone else’s sex life, using
          explicit language, or making jokes about sex.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.5</strong> Disrespecting personal space and/or privacy, like
          standing unnecessarily close, or commenting on personal appearance,
          perceived gender identity, sexual orientation, or asking unrelated
          personal questions.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.6</strong> Failing to respect others may lead to your
          deactivation from the Flexio platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.7</strong> Keeping the Flexio community safe, friendly, and
          helpful for all means that all users of our platform have a
          responsibility to treat each other and all third-parties they
          encounter while using the platform with respect and dignity.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>5.8</strong> Any user found to have violated the
          Non-Discrimination Policy or Respect Others Policy, will lose all
          access to and use of the Flexio platform.
        </p>
      </p>
      <p>
        <strong>6. Confidential Information</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>6.1</strong> When using the Flexio platform to receive or
          perform services, all users may have access to the confidential
          information of third parties, including without limitation, that of
          Flexio, Drivers, Senders, and/or Stop recipients as well as that of
          those third-parties’ respective personnel, contractors, and customers.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>6.2</strong> “Confidential Information” means all information,
          whether oral or written, and whether contained on electronic media or
          otherwise, to which a user is given or has access to, or is made
          available to the user in connection with their use of the platform,
          including without limitation names, addresses, telephone numbers and
          any other information which may be used to distinguish or trace an
          individual’s identity, either alone or when combined with other
          personal or identifying information that is linked or linkable to a
          specific individual (“PII” or “Personally Identifiable Information”),
          and health information protected (“PHI,” and “Protected Health
          Information”) under the Health Insurance Portability and
          Accountability Act (“HIPAA”), Bank Secrecy Act (“BSA”) or any other
          Federal, State or Local law or regulations.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>6.3</strong> It is important to recognize that non-PII can
          become PII whenever additional information is made available — in any
          medium and from any source — if, when combined with other available
          information, it could be used to identify a specific individual
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>6.4</strong> By using the Flexio platform to receive or
          perform services, all users may gain knowledge of third parties’
          confidential, proprietary, personally identifiable and/or protected
          health information.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>6.5</strong> You expressly acknowledge and agree that you, as
          a user of the Flexio platform, are subject to a strict duty to
          maintain absolute confidentiality of all such confidential and
          personal information and you understand that under no circumstances
          may you disclose such information to any other person or use such
          information for any other reason than to receive or perform services
          via the Flexio platform as intended.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>6.6</strong> If Flexio collects or uses your personal
          information in connection with your access to and use of the Flexio
          platform, our services and/or the delivery services, such collection
          and/or use is described and governed by our privacy policy.
        </p>
      </p>
      <p>
        <strong>
          7. Senders and Senders’ responsibilities - Senders Agreements,
          Representations and Warranties.
        </strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.1</strong> Prior to using any services, you agree that you
          will have all right and authority to lawfully use the services or
          otherwise have the full right and authority to contract for the Job
          and send all items contained in a Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.2</strong> You agree to specifically list and disclose all
          items contained in your Job and to not include any “Prohibited Items”.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.3</strong> You agree to properly pack all items in your Job
          sufficiently to withstand transport, ensure the safety and integrity
          of the item and in compliance with all applicable law.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.4</strong> You acknowledge and agree to Flexio’s Open Box
          Policy (as set forth below) and will allow a Driver to visually
          inspect all items in a Job prior to departing with the Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.5</strong> You acknowledge that a Driver is{" "}
          <strong>NEVER</strong> obligated to carry anything that the Driver has
          not been given the right to inspect and agree that a Driver can always
          cancel your Job without penalty if you do not allow inspection of your
          Job contents upon the Driver’s request.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.6</strong> You acknowledge that Drivers are not movers and
          do not deliver Jobs up or down stairs or inside a private residence
          and agree that neither you nor any Stop recipient will require or
          request a Driver to deliver a Job up or down stairs or inside a
          private residence and you will ensure that your Stop recipient is
          aware of the same.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.7</strong> The Sender shall be solely responsible for
          configuring their Pickup and Delivery Validations, Return Processes,
          Notifications, and other relevant settings available on the client
          portal's settings page. It is the Sender's obligation to ensure that
          these configurations are set up accurately and updated as needed to
          reflect their current operational requirements by the Sender.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.8</strong> The Sender is responsible for providing all
          minimum required data, including accurate Delivery addresses and/or
          Coordinates, as necessary for the effective processing and delivery of
          shipments. In the event that the Sender fails to provide accurate and
          complete address information or coordinates, Flexio shall not be held
          responsible for correcting this data or for any costs associated with
          the redelivery process. The Sender acknowledges that inaccuracies in
          provided data may lead to delivery delays or failures, for which
          Flexio bears no liability.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.9</strong> You acknowledge and agree that Flexio’s maximum
          liability for loss, theft, or damage to items in your Job is subject
          to and determined by Flexio’s Carrier Liability Program (see details
          in the Carrier Liability Program, Annex I).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.10</strong> You agree not to knowingly arrange for, or
          solicit, a Driver to perform Jobs for you outside of the Flexio
          platform; provided that nothing herein shall constrain your ability to
          acquire Driver capacity via another platform or a delivery service and
          in connection therewith utilize Drivers who may provide services via
          such other platform or delivery service, or use Drivers who may
          approach you directly, or prohibit you from soliciting any person by
          or through any general advertising medium, not specifically targeted
          at any currently active Driver using Flexio.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>7.11</strong> You agree not to enter any personal information,
          including PII, into the title of a Job, including phone numbers or
          addresses and agree that all personal information should be entered in
          the appropriate sections indicated in the platform when requesting
          delivery services.
        </p>
      </p>
      <p>
        <strong>8. Indemnification</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>8.1</strong> Driver hereby agrees to release, indemnify,
          defend, hold harmless, pay directly or reimburse upon demand Flexio
          Indemnitees from and for any and all any and all losses incurred in
          connection with any claims arising directly or indirectly from, or as
          a result of or in connection with, your or your helper’s or
          personnel’s: (i) acts or omissions or misconduct in the performance of
          delivery services or your other obligations under the T&Cs; (ii)
          breach of the Flexio T&Cs; (iii) violation of any applicable law or
          the rights of any third party, including, without limitation, other
          users of the Flexio platform, Stop recipients, motorists, pedestrians
          and other third parties; (iv) disclosing or using confidential
          information for any reason and in any manner except providing delivery
          services hereunder; (v) ownership, use or operation of a any vehicle
          used during the performance of delivery services, including voluntary
          or involuntary vehicle collisions within the jurisdiction, be it
          private or public property; (vi) failure to have required and
          sufficient insurance, licenses, permits, training or other
          authorizations to provide delivery services; and/or (vii) failure to
          pay of all federal, state and local taxes, and other tax liabilities
          including, but not limited to all payroll taxes, self-employment
          taxes, workers compensation premiums, assessments, and any
          contributions imposed or required under federal, state and local laws.
        </p>
      </p>
      <p>
        <strong>9. Cancellation of Stops by Senders</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>9.1</strong> If You need or want to cancel a Job, please
          cancel your request as soon as possible. This will notify the accepted
          Driver and free them up to offer on and accept other Jobs from other
          Senders. To cancel a Job, contact the Flexio support team (“Flexio
          Support”) as soon as possible or select “Cancel Delivery” within the
          Flexio web portal.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>9.2</strong> Flexio may charge a Sender a fee (“Cancellation
          Fee”) if the Sender cancels the Job once a Driver has started to drive
          to the Job pick up location, or if the Driver cancels the Job because
          all items are not ready to be picked up upon the Driver’s arrival at
          the Sender’s pickup location, or the Sender did not provide an
          accurate pickup address, requested the wrong size vehicle, input the
          wrong dimensions and/or weight of items, and/or the Job does not fit
          in the vehicle assigned, or the Job contains a prohibited item.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>9.3</strong> The Cancellation Fee shall not apply in the event
          the cancellation is done with at least twelve (12) hours’ notice from
          the Job Time.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>9.4</strong> The cancellation fee is automatically charged to
          your account.
        </p>
      </p>
      <p>
        <strong>10. Open Box Policy and Packaging</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>10.1</strong> Flexio encourages the use of no or minimal
          packaging both to promote sustainability and to prevent fraud and use
          of our platform for illegal activities. When packaging is needed or
          desired, Flexio promotes and strongly encourages all Senders to use an
          Open Box Policy. An Open Box Policy means you should always leave
          packaging open so that a Driver can inspect the contents before taking
          a pickup photo and leaving with your Job. If you don’t leave the
          packaging open a Driver always can require you to open the packaging
          to inspect items before leaving with the Job or cancel your Job
          without penalty if you do not allow inspection of your Job contents
          upon their request. Every Driver has a right to know what they are
          taking along on their ride, and by using an Open Box Policy, you help
          us ensure that the Flexio platform is not used to send prohibited
          items, including without limitation, illegal substances. The Open Box
          Policy does not apply to Jobs containing confidential information as
          defined in Section 6.2.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>10.2 </strong> Notwithstanding Flexio’s encouragement to use
          no or minimal packaging, you still have the responsibility to package
          items in your Job appropriately based on level of risk, fragility, and
          other factors specific to the individual item, as determined by you.
          Flexio cannot know what packaging is necessary for any item you send.
          Please use your best judgment when packing your Job as Flexio is not
          liable for loss or damage to any Job if it is not properly packed to
          withstand the safety and integrity of the item during delivery or in
          compliance with all applicable law.
        </p>
      </p>
      <p>
        <strong>11. Flexio Protection Plan</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>11.1</strong> Flexio wants all Senders to feel confident that
          their Job is in good hands, the Flexio Protection Plan provides
          Senders with protection against loss, damage, or theft to items in
          eligible Jobs (see the Terms and Conditions of the Flexio’s Carrier
          Liability Program, Annex I).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>11.2</strong> For the full terms of Flexio’s Carrier Liability
          Program and how to file a claim, please contact our support team via
          electronic mail:{" "}
          <a href="support@goflexio.com">support@goflexio.com</a>.
        </p>
      </p>
      <p>
        <strong>12. Drivers and Drivers’ Responsibilities</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.1</strong> After forming an account on the Flexio platform
          as a Driver, Driver may be notified of available Jobs and/or Jobs
          which you may offer to perform or schedule. Nothing in these T&Cs or
          otherwise guarantees you will be notified of Jobs or your offer to
          perform any Job will be accepted or that you will be given access to
          the Job scheduler. Flexio has no control over the volume or type of
          Jobs that Senders send at any time, including during a Job, and you
          are not guaranteed to be paired with any particular type of Job or
          Sender during a Job or otherwise.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.2</strong> Driver will never under an obligation to offer
          to perform any Job or to schedule any Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.3</strong> Driver will have the sole discretion to offer or
          not offer to perform any Job or schedule any Job posted; provided, if
          your offer is accepted, or you begin a Job, you are then contractually
          bound under these T&Cs to complete all Job(s) you are paired with, as
          per the Sender’s instructions and in accordance with the Sender
          requirements.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.4</strong> The real-time tracking and full photographic
          chain of custody features in the Flexio platform are embedded and are
          integral to your performance of Jobs in accordance with Sender
          requirements. The Flexio platform automatically sends prompts and
          notifications to Driver, provides real-time tracking and status
          updates to Senders and Stop recipients during the performance of the
          Job, sends copies of pickup and delivery photos to Senders, and gives
          Flexio support the ability to communicate with Driver, Senders, and
          Stop recipients in order to facilitate the completion of the Job as
          needed.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.5</strong> Driver acknowledges that his/her personal phone
          number will be made available to the Sender so that the Sender and/or
          Stop recipient may contact Driver directly to facilitate the
          completion of the Job as needed.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.6</strong> Flexio does not have the right to, and shall
          not, control the manner or the method of accomplishing any Job to be
          performed by the Driver. Driver is solely responsible for determining
          whether or not Driver meets the Sender requirements for a posted Job
          before offering on it, and the most effective, efficient, and safe
          manner to perform the Job, including determining the manner of pickup,
          delivery, and final route selection.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.7</strong> Driver does not and will not have a supervisor
          or any individual at Flexio who supervises the performance of Driver
          Jobs and Driver does not and will not be required to make regular
          written or oral reports to any person at Flexio with respect to the
          performance of Driver Jobs, and Driver will not receive performance
          evaluations from Flexio.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.8</strong> Flexio does not and will not require any
          specific make or model of the vehicle you use to perform Jobs. Sender
          requirements and Job details set forth any requirements for the
          vehicle to be used by the Driver to perform the Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.9</strong> Driver will not be required to wear a uniform
          designating Flexio as the provider of your delivery services or use
          signage or other designation of Flexio on your vehicle.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.10</strong> Driver acknowledges and agrees that Driver is
          solely responsible for all costs and expenses related to your use of
          the Flexio App and the performance of Jobs you choose to provide using
          the Flexio App, including without limitation, purchasing and
          maintaining: vehicle(s) and all expenses incurred therewith, including
          licensing and registration, gas, needed repairs and ongoing
          maintenance; cellular equipment and data access, and all expenses
          incurred therewith, including monthly service charges and maintenance;
          such equipment as may be required by a particular Sender or as may be
          needed to perform any particular Job Driver offers to perform, such as
          trailers, hitches, lifts, hand trucks, blankets, and such other
          equipment listed by a Sender in the Job details; licenses, permits
          and/or training as may be required to perform the Jobs Driver offers
          to perform in accordance with all applicable law, industry standards
          and Sender requirements; and insurance coverage related to your use of
          the Flexio App and the performance of the Jobs Driver chooses to
          perform, including without limitation, automobile liability insurance,
          workers’ compensation insurance, occupational hazard insurance, and
          health insurance coverage.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>12.11</strong> Driver acknowledges that standard automobile
          liability insurance may not provide you coverage while performing a
          Job or while logged into the Flexio App. You are responsible for
          consulting your insurers to ensure that you have insurance that covers
          your performance of a Job and your use of the Flexio App before you
          offer to perform a Job.
        </p>
      </p>
      <p>
        <strong>13. Sender Requirements (for Drivers)</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>13.1</strong> All Senders expect that Drivers will perform
          Jobs in a timely, safe, professional, and workmanlike manner and in
          accordance with applicable industry standards and all applicable law.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>13.2</strong> Driver acknowledges that these Sender
          requirements (“Sender Requirements”) are applicable to all Drivers and
          Jobs, and Driver agrees that during the performance of Jobs Driver,
          any helper (as defined below) used, or Driver’s personnel (as defined
          below) will:
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2a</strong> Possess the proper vehicle, equipment,
            licenses, permits, training, and/or insurance as are required by a
            Sender in order to perform any particular Job you offer to perform,
            applicable law, or recommended by applicable industry standards.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2b</strong> Use vehicles in good operating condition and,
            on the outside, clean and well maintained without material physical
            damage, and on the inside, smoke, and odor free and free of
            excessive pet dander.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2c</strong> Not use open convertibles or open truck beds
            in the performance of Jobs containing perishable or delicate items.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2e</strong> Not wear clothing displaying the logo or
            brand of Flexio or any of its affiliates, or a particular Sender
            while performing Jobs or which is obviously and generally offensive
            or unprofessional.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2f</strong> Not smoke, including on the way to pick up a
            Job.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2g</strong> Not engage in activities that distract from
            the performance of the Job, including being under the influence of
            illegal drugs and/or alcohol or illegally talking or texting while
            driving.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2h</strong> Not disable your device’s geolocation
            software while performing Jobs.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2i</strong> Not enter any Stop recipient’s home, nor
            enter or access any secure, private or employee-only areas in any
            place of business.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2k</strong> Not carry on your person any personal weapon
            or other item designed or used for inflicting bodily harm or
            physical damage (including knives and firearms) when entering any
            pickup or drop off location.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2l</strong> Not be accompanied by minors or unnecessary
            helpers or personnel; provided further that you and/or your
            personnel shall not be accompanied by any other person if the Job
            contains prescription medicine or pharmaceuticals.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2m</strong> Sender requirements have been established by
            one or more Senders and are being communicated to you herein in
            accordance with our contractual obligations and indicate what
            Sender’s desire and what they deem is acceptable performance by you.
            They are not meant to be exclusive; a Sender may have additional
            requirements and instructions that will be set forth in the Job
            details or otherwise made available to you.
          </p>
          <p style={{ marginLeft: "1em" }}>
            <strong>13.2n</strong> You acknowledge that any provisions in these
            T&Cs setting forth requirements, qualifications, specifications or
            performance standards, including the Sender requirements, or having
            the effect of reserving ultimate authority in Flexio for ensuring
            the same, have been inserted at the request and on behalf of the
            Senders who are using the Flexio platform, and are included
            specifically for their benefit, for the safety of the other users of
            the Flexio platform, Stop recipients and members of the general
            public, and/or to achieve compliance with applicable law.
          </p>
        </p>
      </p>
      <p>
        <strong>14. Driver Agreements, Representations and Warranties</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.1</strong> Driver represents and warrants that is at least
          eighteen (18) years of age, or such older age as may be required in a
          state or other jurisdiction that restricts the ability to enter into
          agreements due to age. If You are not the required age, you must not
          use the platform and you may not perform Jobs or schedule Jobs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.2</strong> Prior to offering to provide any delivery
          services, the Driver agrees that will have full right and authority to
          contract to perform the Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.3</strong> Driver agrees to comply with all applicable law
          while using the platform and offering on Jobs including, but not
          limited to, labor and employment laws such as the Immigration Reform
          and Control Act of 1986, as amended, and the Illegal Immigrant Reform
          and Immigrant Responsibility Act of 1996, as amended.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.4</strong> Driver agrees to have a mobile device with the
          Flexio App running, and to not disable the Flexio App or its
          geolocation software at any time while performing Jobs and Jobs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.5</strong> Driver agrees that his/her personal phone number
          being made available to the Sender and/or Stop recipient and authorize
          communications between the Flexio platform, you, and Senders and/or
          Stop recipients, and authorize Flexio support, Senders and/or Stop
          recipients to communicate with, and/or initiate communications
          between, you, Sender and/or the Stop recipient directly or on your
          behalf, solely as needed to facilitate the completion of the Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.6</strong> Driver agrees to use a true facial picture in
          your profile when creating a Flexio account.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.7</strong> Driver represents and warrants that operates an
          independently established business or enterprise that provides
          delivery, courier, or similar services regularly.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.8</strong> Driver agrees that Flexio will not reimburse you
          for any expenses or costs related to your use of the Flexio platform
          or the performance of any Job, and you agree to not seek reimbursement
          of the same from Flexio or any Sender.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.9</strong> Driver represents and warrants that will possess
          all equipment and vehicles required by a Sender to perform a Job{" "}
          <strong>PRIOR</strong> to offering to perform the Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.10</strong> Driver agrees that will not offer to perform
          Jobs unless you are physically capable of handling the Job and agree
          that and Flexio will not pay or reimburse you for medical care, or any
          other claims, costs or expenses you may incur if you are hurt or
          injured while performing a Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.11</strong> Driver agrees that at all times when using the
          Flexio App to perform Jobs you will maintain current, valid insurance,
          in amounts and of types required by applicable law and your
          insurer(s), including automobile liability, commercial auto coverage,
          health insurance, workers’ compensation and/or occupational accident
          insurance. The valid insurance shall include Flexio as an additional
          insured and loss payee.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.12</strong> Driver represents and warrants that his/her
          insurance coverage is sufficient insurance to cover any damages,
          injuries, or harm you may incur or cause arising out of, or related to
          your use of the Flexio App and the performance of the Jobs you offer
          to perform, including damages to your or a third party’s property and
          costs of medical care you or a third party may require due to injury.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.13</strong> Driver agrees that is solely responsible, and
          will not be reimbursed for, any expenses or costs related to your
          obtaining or maintaining any insurance coverage and you agree to
          deliver to Flexio upon request, current certificates of insurance as
          proof of coverage.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.14</strong> Driver agrees that prior to offering to perform
          a Job, and at all times when performing a Job requiring a license,
          permit, or training, you will possess and maintain current, valid
          licenses, permits and training certifications as required by
          applicable law, Sender requirements and as recommended by industry
          standards.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.15</strong> Driver agrees is solely responsible for, and
          will not be reimbursed for, any expenses or costs related to Your
          obtaining or maintaining any permits, licenses, or training
          certifications.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.16</strong> Driver agrees to notify Flexio promptly if you
          discover, or suspect, any prohibited item(s) in a Job and observe the
          best practices found here: Prohibited Items and Suspicious Locations.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.17</strong> Driver understands that the Open Box Policy
          does not apply to Jobs confidential information as defined in Section
          6.2 and 10.1.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>14.18</strong> Driver agrees to promptly remove any
          confidential information, including any PII and PHI transmitted or
          disclosed to you via the Flexio App and through your performance of a
          Job from your possession, including stored on your personal devices,
          including by deleting all pickup and delivery photographs or other
          information (whether or not containing Confidential Information)
          within fifteen (15) days of a completed Job, or promptly upon notice
          from Flexio.
        </p>
      </p>
      <p>
        <strong>15. Flexio Jobs</strong>
        <p style={{ marginLeft: "1em" }}>
          In addition, all users are participating in Flexio Jobs, you further
          acknowledge and agree:
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>15.1</strong> During a Job, Driver will only perform those
          Jobs where the Driver is paired with the chosen Job. Driver will not
          be able to offer other Jobs during the assigned Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>15.2</strong> During a Job, the Driver will perform all Jobs
          in his or her chosen zone that are sent to the Driver in the Driver
          App.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>15.3</strong> The Jobs Driver receives during the chosen Job
          will be varied in the number of items and Jobs, estimated time for
          completion, and estimated distance.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>15.4</strong> During a Job, the Driver will only perform those
          Jobs where the Driver is paired with the chosen Job, and the Driver
          will not be able to offer any other Jobs during your Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>15.5</strong> If the Driver cannot complete all Jobs received
          by the end of the Job (for reasons not attributable to the Driver, the
          Driver’s vehicle, or the Driver’s negligence or fault), the Job’s
          Earnings Guarantee will be proportionally increased, with a maximum
          cap of 30% of the estimated initial duration, in order to take into
          account, the additional minutes the Job was extended.
        </p>
      </p>
      <p>
        <strong>16. Service Animals</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>16.1</strong> Drivers are not to have animals in their
          delivery vehicle, including service animals unless such service animal
          is necessary to the Driver’s performance of delivery services.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>16.2</strong> If a Driver must have a service animal present
          all Job items must be kept in a separate container that provides
          protection from pet hair and dander.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>16.3</strong> In no event shall a Driver with a service animal
          offer to provide delivery services for Jobs containing food and food
          related items, clothing, or unpackaged items.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>16.4</strong> Drivers should note that service animals may
          cause complaints from certain Senders and or Stop recipients and such
          complaints may lead to a Driver being requested to not perform Jobs
          for certain Senders.
        </p>
      </p>
      <p>
        <strong>17. Driver Helpers and Driver Personnel</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>17.1</strong> To the extent permitted by applicable law and a
          Sender and further subject to these T&Cs, a Driver may be accompanied
          by a “helper” (“Driver Helper”) and/or may hire or engage others as
          employees or subcontractors (collectively “Driver Personnel”) to
          perform a Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>17.2</strong> Helpers: All helpers must be essential to the
          completion of the Driver’s Job in accordance with Sender requirements
          and be accompanied at all times by the Driver of the Flexio account
          that made the offer to perform the Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>17.3</strong> Personnel: A Driver is not required to perform
          Jobs personally, but may, to the extent permitted by applicable law
          and subject to these T&Cs, hire or engage Driver personnel to perform
          Jobs on the Driver’s behalf.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>17.4</strong> A Driver shall be solely responsible for the
          direction and control of any Driver helper or Driver personnel used by
          Drivers hereunder in accordance with these T&Cs and the Sender
          requirements and shall be liable for all actions and omissions of its
          helper or personnel.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>17.5</strong> A Driver has full and sole responsibility for
          the payment of all amounts due to helpers and/or personnel for Jobs
          performed on the Flexio platform or otherwise, including all wages,
          benefits and expenses, if any, and for all required state and federal
          income tax withholdings, unemployment insurance contributions, and
          social security taxes. Neither Flexio nor its affiliates, nor any
          Sender, shall have responsibility for any wages, benefits, expenses,
          or other payments due to a Driver’s helpers or personnel for income
          tax withholding, social security, unemployment insurance
          contributions, or other payroll taxes relating to helpers or
          personnel. Unless specifically mandated by applicable law, Flexio
          shall have no authority to withhold state or federal income taxes,
          social security taxes, unemployment insurance taxes/contributions, or
          any other local, state, or federal tax on behalf of helpers or
          personnel.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>17.6</strong> Notwithstanding the above, you acknowledge that
          Sender requirements provide that under no circumstances may you be
          accompanied by a minor while performing a Job, by any
          unnecessary/non-essential person, including any helper or personnel if
          not needed for the specific Job; or by any other person (including a
          helper or personnel) while performing any Job containing prescription
          medicine or pharmaceuticals.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>17.7</strong> If you intend to use helpers or personnel, you
          must send notice thereof in advance to Flexio at{" "}
          <a href="mailto:support@goflexio.com">support@goflexio.com</a> and
          will receive further directions.
        </p>
      </p>
      {/* <p>
        <strong>18. Indemnification</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.1</strong> You hereby agree to release, indemnify, defend,
          hold harmless, pay directly or reimburse upon demand Flexio
          Indemnitees from and for any and all any and all losses incurred in
          connection with any claims arising directly or indirectly from, or as
          a result of or in connection with, your or your helper’s or
          personnel’s: (i) acts or omissions or misconduct in the performance of
          delivery services or your other obligations under the T&Cs; (ii)
          breach of the Flexio T&Cs; (iii) violation of any applicable law or
          the rights of any third party, including, without limitation, other
          users of the Flexio platform, Stop recipients, motorists, pedestrians
          and other third parties; (iv) disclosing or using confidential
          information for any reason and in any manner except providing delivery
          services hereunder; (v) ownership, use or operation of a any vehicle
          used during the performance of delivery services; (vi) failure to have
          required and sufficient insurance, licenses, permits, training or
          other authorizations to provide delivery services; and/or (vii)
          failure to pay of all federal, state and local taxes, and other tax
          liabilities including, but not limited to all payroll taxes,
          self-employment taxes, workers compensation premiums, assessments, and
          any contributions imposed or required under federal, state and local
          laws.
        </p>
      </p> */}
      <p>
        <strong>
          18. Actions Leading to Temporary Locks or Permanent Deactivations of
          User Accounts
        </strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.1</strong> Without limiting any other of its remedies at
          law or in equity, Flexio, in its sole discretion, may temporarily lock
          Your account and initiate an investigation potentially leading to Your
          permanent deactivation if:
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.2</strong> You are suspected of breaching, or are credibly
          alleged to have breached, any of the T&Cs applicable to you, including
          any of your user agreements, representations, or warranties, or any of
          Flexio’s other policies governing your use of the platform, including
          those set forth in support articles.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.3</strong> We are unable to verify or authenticate any
          information you provide to us when using or receiving the services.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.4</strong> We believe that your actions may cause financial
          loss or legal liability to any other user, Flexio, its affiliates, or
          its third-party providers.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.5</strong> We suspect, we find, or it has been credibly
          alleged that you have engaged in fraudulent, illegal, dangerous, or
          harmful activity in connection with your use of or access to the
          Flexio platform, or in connection with your receiving or providing
          services, including theft.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.6</strong> We receive a claim that items in your Job are
          missing or damaged.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.7</strong> Prohibited items are discovered in your Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.8</strong> If you are a Driver and you cancel your Jobs
          after acceptance without reasonable cause.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.9</strong> If you are Driver and you are a “no-show” to
          pick up your Job or start your Job without reasonable cause.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.10</strong> You receive multiple complaints or low ratings,
          as a Driver or Sender
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.11</strong> You have not offered a Job if you are a Driver,
          or posted a Job if you are a Sender, for 60 days or more.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.12</strong> A user’s account will remain locked during the
          investigation of any incident or action for such time as Flexio
          determines is necessary, in its sole discretion.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.13</strong> Flexio may reactivate any user whose account
          was temporarily locked in its sole discretion.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.14</strong> If Flexio permanently deactivates your account,
          you may be eligible for an appeal, as set forth below.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.15</strong> You hereby grant Flexio permission and the full
          right and authority to fully investigate and prosecute violations of
          any of these terms (including all documents and agreements referenced
          or incorporated herein) to the fullest extent of the law, including
          involving, cooperating, or disclosing your personal information to
          insurance companies, pursuant to a valid subpoena, and law enforcement
          authorities in connection therewith.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>18.16</strong> Without limiting our other remedies, we may
          withhold Job payments, limit, lock or deactivate your user account,
          prohibit access to the platform, apps, websites, and their content
          (including your own), services and tools, delay or remove hosted
          content, and take technical and legal steps to prevent any user from
          accessing the platform, apps, websites or any portion thereof if we
          believe that they are creating risk or possible legal liabilities,
          infringing the intellectual property rights of third parties, or
          acting inconsistently with the letter or spirit of these terms and
          other published Flexio terms or policies.
        </p>
      </p>
      <p>
        <strong>19. Appeal Process</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>19.1</strong> Flexio does not make the decision to permanently
          deactivate a user’s account without a complete investigation. However,
          in some instances, we allow a user to appeal Flexio’s decision to
          permanently deactivate their account.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>19.2</strong> We will not accept appeals for certain
          deactivation decisions related to “zero tolerance” violations, such as
          criminal or fraudulent activity while using the platform or breaches
          of the Non-Discrimination or Respect Other Policies.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>19.3</strong> Because it is impossible to anticipate the full
          range of reasons that could result in the permanent deactivation of a
          user’s account, ultimate determination of eligibility will be made by
          Flexio on a case-by-case basis. A user will be allowed only one
          appeal. All appeal decisions made by Flexio are final and not subject
          to further appeal.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>19.4</strong> You can find the full details of Flexio’s appeal
          process in the email you received notifying you of the deactivation of
          your account. If you believe your deactivation is eligible for an
          appeal, please submit your appeal by following the instructions in
          your deactivation email.
        </p>
      </p>
      <p>
        <strong>20. Deactivated Accounts</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>20.1</strong> If your account is permanently deactivated your
          account information will be deleted in accordance with Flexio’s
          Privacy Policy and you will be removed from the Flexio platform and no
          longer have access to or use of the Platform. Permanent deactivation
          will cause you to lose your username and persona as well as any
          privileges, certifications, badges, or other earned items associated
          with your account. If your account was not deactivated for cause, you
          may open a new account on the Platform. It will be considered a
          material breach of these T&Cs if a user who has been deactivated for
          cause, opens a new account on the Platform and such new account will
          be deactivated without notice or right to appeal. Flexio is under no
          obligation to compensate any user for any losses as a result of a
          user’s deactivation.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>20.2</strong> All Flexio actions taken with respect to the
          temporary lock and/or permanent deactivation of your account may be
          taken at Flexio’s sole discretion, with or without notice, and without
          liability to you.
        </p>
      </p>
      <p>
        <strong>21. Billing and Payment</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>21.1</strong> Under these T&Cs, Flexio is not and will not be
          a party to the agreements made between users for delivery services
          using our platform, including the Sender’s agreement and
          responsibility to pay the Driver, therefore. In order for Flexio to
          facilitate payments to be made and received for delivery services
          hereunder, all users of our platform are required to provide to Flexio
          their credit card or bank account details and such other information
          needed to vet the user by the Payment Service Provider retained by
          Flexio (the “PSP”).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>21.2</strong> Client authorizes Flexio to automatically debit
          their Credit Card or Bank Account as authorized, upon the completion
          of a Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>21.3</strong> By accepting these T&Cs, each user agrees that
          they have downloaded or printed, and reviewed and agreed to the PSP
          Agreement. Please note that Flexio is not a party to the PSP Agreement
          and has no obligations or liability to any user under the PSP
          Agreement.
        </p>
      </p>
      <p>
        <strong>22. Payment Processing</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>22.1</strong> Payment processing services are provided by
          Transactis and/or Quickbooks and/or Stripe and subject to their terms,
          available at{" "}
          <a href="https://www.transactis.com">https://www.transactis.com</a>,{" "}
          <a href="www.quickbooks.com">www.quickbooks.com</a>, or{" "}
          <a href="www.stripe.com">www.stripe.com</a>.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>22.2</strong> By using the Flexio platform to pay for Jobs,
          all users agree to be bound by the Terms and Conditions from this
          agreement and agree to the pricings provided before accepting Jobs and
          to its service terms, or if Software as a Service, agree to the
          pricing provided in
          https://www.goflexio.com/software#:~:text=pay%20per%20use.-,Pricing,-Monthly.
          Both pricings may be modified from time to time.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>22.3</strong> As a condition of Flexio enabling payment
          processing services through one of Flexio’s providers per clause 23.1,
          all users authorize Flexio to obtain all necessary access and perform
          all necessary activity on to facilitate payment for services as
          contemplated by these T&Cs and your relationship with Flexio.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>22.4</strong> All users further agree to provide accurate and
          complete information about you and your business and authorize Flexio
          to share transaction information with one of Flexio’s providers per
          clause 23.1for the purposes of facilitating the payment processing
          services provided by one of Flexio’s providers per clause 23.1. Flexio
          reserves the right to switch payment processing vendors or use
          alternate or backup vendors at its discretion.
        </p>
      </p>
      <p>
        <strong>23. Instant Pay</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>23.1</strong> Drivers may elect to receive payments through
          Flexio’s Instant Pay feature (which Flexio may offer to You in its
          sole discretion and availability).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>23.2</strong> If you elect to use Instant Pay, you acknowledge
          that you will be charged a fee for this service as disclosed by Flexio
          prior to your election.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>23.3</strong> You understand that your financial institution
          may not support Instant Pay, neither Flexio nor Instant Pay make any
          guarantee concerning how quickly payments will settle with your
          account, and not all payments through the Flexio platform are eligible
          for Instant Pay.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>23.4</strong> If you elect to use any direct debit features
          made available by Flexio or its third-party partners, you acknowledge
          that you may be subject to supplemental agreements related to those
          services.
        </p>
      </p>
      <p>
        <strong>24. Billing</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>24.1</strong> Senders will be responsible for the payment of
          each Job, or accumulated Jobs, as per the Terms and Conditions of the
          Schedule provided on Clause 23.2.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>24.2</strong> Senders are responsible for paying for each Stop
          contracted for, which will include:
          <br />
          <strong>(i)</strong> the pricing terms of the Job provided to the
          Driver. <br />
          <strong>(ii)</strong> reimbursement of documented tolls and parking
          fees incurred by Driver in connection with the Job (if such Sender has
          agreed to reimburse for the same). <br />
          <strong>(iii)</strong> any cancellation or wait fees, as may be
          applicable to the Job, and <br />
          <strong>(iv)</strong> any other fee Flexio may assess for use of its
          Platform (combined, the “Job Payment”).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>24.3</strong> Unless otherwise agreed by Flexio, any fees that
          Flexio may charge a user for their use of the Flexio platform
          (including to receive or perform any services), are due immediately
          and are non-refundable.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>24.4</strong> This no refund policy shall apply at all times
          regardless of your decision to terminate your usage of our platform,
          our decision to terminate your usage of our platform, disruption
          caused to our platform or the Services either planned, accidental or
          intentional, or any reason whatsoever.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>24.5</strong> Flexio reserves the right to change pricing at
          any time and any pricing information published on the Flexio Website
          may not reflect the current pricing.
        </p>
      </p>
      <p>
        <strong>25. Taxes</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>25.1</strong> Users of the platform will be liable for any
          taxes (including IVU, if applicable) required to be paid because of
          the user’s use of or access to the Flexio platform or receiving or
          performing any services or on any Job payment received (other than
          taxes on the Flexio’s income).
        </p>
      </p>
      <p>
        <strong>26. Payment, Withholding and Release</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>26.1</strong> If accepted for a Job, the Driver is solely
          responsible for completing delivery of the Job as agreed upon{" "}
          <strong>BEFORE</strong> being paid.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>26.2</strong> Flexio, in its sole discretion, may place a hold
          on a Driver’s Job payment if the delivery of the Job is not provided
          expressly in accordance with the Sender requirements and any other
          directions set forth in the request for delivery services, and these
          T&Cs, and/or Flexio determines it otherwise necessary or advisable to
          place a hold on such Job payment, including without limitation if
          Flexio has any suspicion or reason to believe that a Driver or Sender
          has breached these T&Cs or any applicable law.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>26.3</strong> If a Job payment is placed on hold, Flexio will
          release a Job payment to a Driver upon establishing the validity of
          the accounts and Jobs under suspicion, in Flexio’s sole but reasonable
          discretion.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>26.4</strong> Driver agrees that any and all Job payments are
          set forth in the Job as posted and are paid to Driver in accordance
          with the payment method chosen by such Driver.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>26.5</strong> All Drivers agree that all Job payments are
          facilitated via the Flexio platform through the PSP, and they will not
          seek any Job payment directly from a Sender or the recipient of a
          Stop, including a store merchant.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>26.6</strong> Driver hereby waives any and all right to
          commence any action for a Job payment directly from a Sender or a
          recipient on the Flexio platform.
        </p>
      </p>
      <p>
        <strong>27. Reimbursement for Parking Fees and Tolls</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>27.1</strong> Some Senders may choose to reimburse a Driver
          for the cost of parking fees and tolls incurred while performing a Job
          or multiple Jobs during a Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>27.2</strong> If a Sender has agreed to reimburse Drivers for
          parking fees and tolls, the Job as posted on the platform will
          indicate that parking and tolls will be reimbursed and provide the
          Driver functionality to upload their receipt(s) after completing the
          Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>27.3</strong> A Driver will be reimbursed for parking fees and
          tolls incurred and paid for by Driver while performing a Job ONLY if
          the Driver uploads their receipts (or other adequate evidence of
          incurring the same) to Flexio within fifteen (15) days of incurring
          the expense.
        </p>
      </p>
      <p>
        <strong>28. Gratuities</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>28.1</strong> Although gratuities are not established within
          the Flexio Platform, Senders and delivery order recipients may give
          gratuities to Drivers and Drivers shall retain 100% of any gratuity
          paid.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>28.2</strong> Notwithstanding the above, the Driver
          acknowledges that once they offer to perform a Job, they have agreed
          to perform the Job for the amount set forth in the request for
          delivery services.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>28.3</strong> Any decision to give additional gratuity or
          further compensation is completely voluntary and Driver agrees that
          they will not and will ensure that their helpers and personnel do not
          ask for or insinuate any obligation for additional compensation or
          gratuities from any Sender or Stop recipient.
        </p>
      </p>
      <p>
        <strong>29. Unexpected Costs</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>29.1</strong> Driver agrees that the Job payment amount will
          not be increased to cover any expected or unexpected costs or expenses
          Driver, or its personnel incur while performing Jobs, including for
          additional gas expenses or damage to a Driver’s or its personnel’s
          vehicles and equipment, and Driver acknowledges that their costs and
          expenses may be higher than the amounts earned from performing Jobs
          resulting in an overall loss of income.
        </p>
      </p>
      <p>
        <strong>
          30. Intellectual Property - Flexio Intellectual Property; Ownership of
          the Flexio Platform
        </strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>30.1</strong> Flexio solely owns and shall retain all right,
          title, and interest in and to the Flexio platform, its products,
          services, confidential information, Flexio content (as defined below),
          and its trademarks, service marks, trade names, logos, logotypes,
          insignia, inventions, copyright or patent-protected matter, associated
          therewith, database rights, or other intellectual property software,
          patents, design rights (whether registered or unregistered), trade
          secrets and all other similar proprietary rights whether or not
          currently registered, wherever existing in the world, with full rights
          to apply for protection of the same (“Flexio Intellectual Property”).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>30.2</strong> Any and all drawings, designs, artwork, tapes,
          prints, plates, source code, modifications, extensions, upgrades, and
          derivative works of Flexio’s intellectual property, or any
          discoveries, ideas, inventions, improvements or other materials,
          original or otherwise, belonging to a party, either written or
          readable by machine, regardless of whether any portion thereof is or
          may be validly copyrighted or patented, are included in its
          intellectual property shall remain the property of Flexio and may not
          be copied or duplicated in any manner.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>30.3</strong> Nothing will restrict or limit Flexio’s right to
          use, profit from, disclose, publish, keep secret, or otherwise exploit
          your feedback (as defined below), without compensating or crediting
          you or any individual providing such feedback. “feedback” includes
          suggestions for correction, change, or modification to the Flexio
          platform or the services provided via the Flexio platform; evaluation
          data and reports relating to the performance of the Flexio platform
          and services provided hereunder; and other reactions or ideas for
          improvements relating to the Flexio platform and services provided via
          the Flexio platform.
        </p>
      </p>
      <p>
        <strong>31. Your Access and Use of the Flexio Platform</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.1</strong> Subject to your compliance with the T&Cs, Flexio
          hereby grants to you the limited right to access and use the platform
          on any authorized Android device that you own or control and/or any
          Apple device that you own or control and as permitted by the usage
          rules set forth in Section B of the Services and Content Usage Rules
          (the “Usage Rules“).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.2</strong> This grant does not allow you to use the
          platform on any device that you do not own or control.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.3</strong> The terms of this grant will govern any upgrades
          provided by Flexio that replace and/or supplement the Flexio platform
          unless such upgrade is accompanied by a separate grant in which case
          the terms of that grant will govern.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.4</strong> You acknowledge and agree that the availability
          of the platform is dependent on the third party from which you
          received the application license, e.g., the Apple iPhone or Android
          app stores (“App Store”).
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.5</strong> You acknowledge and agree that this agreement is
          between you and Flexio and not with any App Store.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.6</strong> Flexio, not the App Store, is solely responsible
          for the platform, including the mobile application(s), the content
          thereof, maintenance, support services and warranty therefore, and
          addressing any claims relating thereto.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.7</strong> In order to use the platform, you must have
          access to a wireless network, and you agree to pay all fees associated
          with such access.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.8</strong> You also agree to pay all fees (if any) charged
          by the App Store in connection with the platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.9</strong> You agree to comply with, and your license to
          use the platform is conditioned upon your compliance with, all
          applicable third-party terms of agreement (e.g., the App Store’s terms
          and policies) when using the platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.10</strong> You represent and warrant that you are not
          located in a country that is subject to a U.S. Government embargo, or
          that has been designated by the U.S. Government as a “terrorist
          supporting” country.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.11</strong> You represent and warrant that you are not
          listed on any U.S. Government list of prohibited or restricted
          parties.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.12</strong> You acknowledge and agree that each App Store
          (and its affiliates) is an intended third-party beneficiary of this
          agreement and has the right to enforce the terms and conditions of
          this agreement.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>31.13</strong> Flexio reserves the right to Job offering
          and/or supporting our platform and/or our users’ ability to receive or
          perform services or any particular portion or part of our platform or
          access to services at any time, at which point your right to access
          and use the platform and your access to the services therefrom, or any
          part thereof, will be automatically deactivated. In such an event,
          Flexio shall not be required to provide refunds or other compensation
          to users in connection with any discontinuation of the platform or
          access to services.
        </p>
      </p>
      <p>
        <strong>32. You agree NOT to:</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.1</strong> License, sublicense, sell, resell, transfer,
          assign, distribute or otherwise commercially exploit or make available
          to any third party the platform in any way.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.2</strong> Use or commercially exploit Flexio’s logos,
          trademarks, service marks, and trade names, including on any website
          of yours, or challenge or assist others in challenging Flexio’s
          ownership or registration thereof, or attempt to register any
          trademarks, service marks or trade names confusingly similar to that
          of Flexio’s.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.3</strong> Modify or make derivative works based upon the
          platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.4</strong> Create I\internet “links” to the platform,
          alternate access to services, or “frame” or “mirror” any part of the
          platform on any other server or wireless or internet-based device.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.5</strong> Reverse engineer or access the platform to copy
          any ideas, features, functions, or graphics of the platform whether to
          build competitive products or services using similar ideas, features,
          functions or graphics of the Platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.6</strong> Launch an automated program or script that
          unduly burdens, interferes, disrupts, hinders the integrity, operation
          and/or performance of the platform (or the data contained therein) in
          any way or for any user, including but not limited to Trojan horses,
          viruses, worms, web spiders, web crawlers, web robots, web ants, web
          indexers, bots, or any program which may make multiple server requests
          per second, or send and/or store in our platform material containing
          software viruses, worms, Trojan horses or other harmful computer code,
          files, scripts, agents or programs.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.7</strong> Attempt to gain unauthorized access to the
          platform or its related systems or networks or circumvent or attempt
          to defeat any security or verification measures relating to use of the
          Platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>32.8</strong> Use our platform to send spam or otherwise
          duplicative or unsolicited messages, send or store infringing,
          obscene, threatening, libelous, or otherwise unlawful or tortious
          material, including material harmful to children or that violates
          third-party privacy rights.
        </p>
      </p>
      <p>
        <strong>33. Content</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>33.1</strong>{" "}
          <strong>Licenses granted by Flexio in content:</strong> subject to
          your compliance with the T&Cs, Flexio grants you a limited,
          non-exclusive, non-transferable/sublicensable, non-assignable license
          to view, download and print any content that Flexio makes available
          through the platform or on its social medial pages and feeds (“Flexio
          Content“) and to view any content that a user posts, uploads,
          publishes, submits or transmits to be made available through the
          platform or on Flexio social media pages and feeds, including any
          questions, comments, suggestions, ideas, or feedback (“User Content”
          and with Flexio Content collectively, “Collective Content“). Neither
          Flexio content nor user content includes information provided or
          received by users when performing or receiving delivery services
          hereunder, whether or not confidential information, including pickup
          and delivery photos. No user is granted any license to or right
          therein and may not post or otherwise disclose on social media pages
          and feeds, whether or not Flexio’s, any content containing such media
          and it will be deemed a material breach of these T&C if you do so.
          Collective content may be used solely for your personal and
          non-commercial purposes. You will not use, copy, adapt, modify,
          prepare derivative works based upon, distribute, license, sell,
          transfer, publicly display, publicly perform, transmit, stream,
          broadcast or otherwise exploit collective content, except as expressly
          permitted in the T&Cs. No licenses or rights are granted to you by
          implication or otherwise in any intellectual property rights owned or
          controlled by Flexio or its licensors in such collective content.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>33.2 Social Media:</strong> Flexio maintains certain social
          media pages and feeds. By maintaining these social media pages, Flexio
          intends to build a welcoming community where everyone can engage in
          meaningful dialogue with one another. Our goal is to post and have
          posted relevant, interesting content and we welcome your comments,
          suggestions, and feedback. However, we want all users who contribute
          on Flexio’s social media pages and feeds to be able to express their
          opinions freely and feel comfortable entering into informed debate and
          discussion. When posting, commenting, or otherwise interacting or
          engaging with Flexio’s social media pages, you agree to comply with
          Flexio’s Social Media Guidelines. Flexio has no responsibility to, and
          does not, monitor your access to or use of collective content or
          review user content for the inclusion of illegal or impermissible
          content. Flexio reserves the right, at any time and without prior
          notice, to remove or disable access to any user content that Flexio,
          at its sole discretion, considers to be in violation of its Social
          Media Guidelines or these T&Cs. By posting on our social media pages,
          you represent and warrant that your user content complies with
          Flexio’s Social Media Guidelines and does not contain any content
          prohibited or otherwise restricted therein.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>33.3</strong> License Granted by User: We may, in our sole
          discretion, permit users to post, upload, publish, submit, or transmit
          user content. By making available or posting any user content on or
          through the platform you hereby grant to Flexio a worldwide,
          irrevocable, perpetual, exclusive, transferable, royalty-free license,
          with the right to sublicense, to use, view, copy, adapt, modify,
          distribute, license, sell, transfer, publicly display, publicly
          perform, transmit, stream, broadcast, disseminate and otherwise
          exploit such user content. Flexio shall be entitled to the
          unrestricted use of all user content for any purpose, commercial or
          otherwise, without acknowledgment or compensation to you. Flexio
          claims no ownership rights in any user content and nothing in the T&Cs
          will be deemed to restrict any ownership rights, or other rights that
          you may have to use and exploit your user content. You acknowledge and
          agree that you are solely responsible for all user content that you
          make available through the platform or App. By posting or making
          available any user content, you represent and warrant that you are the
          sole and exclusive owner of all user content that you make available
          or post or you have all right, license, consent and release required
          to grant to Flexio the rights in such user content contemplated under
          the T&Cs; and neither the user content nor your posting, uploading,
          publication, submission or transmittal of the user content or Flexio’s
          use of the user content (or any portion thereof) will infringe,
          misappropriate or violate a third party’s patent, copyright,
          trademark, trade secret, moral rights or other intellectual property
          rights, or rights of publicity or privacy, or result in the violation
          of any applicable law. Sender hereby grants to Flexio a worldwide,
          royalty-free, non-exclusive license to use and display the Sender’s
          name, logos, trademarks, service marks, and trade names (“Marks”) in
          Flexio’s customer list(s), case studies, press releases, advertising
          and other public or promotional statements, including displaying such
          Marks on our Websites and App. User represents and warrants that it
          owns and/or has the right to produce and use its Marks, and any other
          intellectual property that it provides directly to Flexio, including
          through the platform or App, and that those will not infringe any
          patent, copyright, trademark, trade secret, or other intellectual
          property, proprietary, or contract right of any third party. Flexio
          shall use all Marks in compliance with all applicable laws and
          regulations. The ownership and all goodwill accruing to the Marks
          arising directly from its use by the Flexio shall vest in and inure to
          the benefit of the Sender.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>33.4</strong> Third-Party content and Interactions: Via the
          Flexio platform, you may enter into correspondence with, purchase
          goods and/or services from, or participate in promotions of third
          party service providers, advertisers or sponsors showing their goods
          and/or services through the platform (“Third-party Content”). Any such
          activity, and any terms, conditions, warranties, or representations
          associated with such activity, is solely between you and the
          applicable third party. Flexio, its affiliates and its licensors shall
          have no liability, obligation or responsibility for any third-party
          content, or such agreements, correspondence, purchase, transaction, or
          promotion between you and any such third- party. Flexio does not
          endorse any third-party content, or sites on the Internet that are
          linked through our platform, and in no event shall Flexio, its
          affiliates or its licensors be responsible for any third-party
          content, products, services, or other materials on or available from
          such sites or third-party providers. Flexio may rely on third-party
          advertising and marketing supplied through the platform and other
          mechanisms to subsidize the platform. By agreeing to these T&Cs you
          agree to receive such advertising and marketing. If you do not want to
          receive such advertising, you should notify us in writing.
        </p>
      </p>
      <p>
        <strong>34. Miscellaneous - No Warranties, Express or Implied</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>34.1</strong> The services and our platform are provided to
          you strictly on an “as is” basis without warranties of any kind,
          either express or implied, including, but not limited to, warranties
          of merchantability, fitness for a particular purpose and
          non-infringement and all warranties are hereby disclaimed by Flexio to
          the maximum extent permitted by applicable law.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>34.2</strong> Without limiting the foregoing, neither Flexio
          nor its affiliates or licensors represent, warrant or guarantee: the
          accuracy or completeness of any content provided through the platform,
          or the content of any websites linked to the platform; that access to
          the services or platform will be uninterrupted, error-free, secure,
          timely or operate on any device or in combination with any other
          hardware, application, system or data; the results that may be
          obtained from the use of the services or platform; that the services
          or platform, or the quality of any products, services, information or
          other material purchased or obtained by you through the platform, will
          meet your requirements or expectations; the timeliness, accuracy, or
          reliability, of any user; the timeliness, accuracy, or reliability of
          our services or platform, or any information or materials provided
          through or in connection with the use of the services, platform or
          app; that the platform is free from viruses, worms, Trojan horses, or
          other harmful components; that any errors or defects in the services
          or platform will be corrected; or that any personal information
          supplied by you will not be misappropriated, intercepted, deleted,
          destroyed or used by others. Additionally, Flexio does not warrant,
          endorse, guarantee or assume responsibility for any service advertised
          or offered by a third-party through the platform or any hyperlinked
          website or featured in any banner or other advertising and Flexio will
          not be a party to or in any way be responsible for monitoring any
          transaction between you and third-party providers of products or
          services.
        </p>
      </p>
      <p>
        <strong>35. Limitations of Liability</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>35.1</strong> Neither Flexio nor its affiliates or licensors
          is responsible for the conduct, whether online or offline, of any user
          of our platform and Flexio and its officers, directors, employees,
          agents, representatives, affiliates, and licensors are not liable for
          any loss, claim, injury, or damage arising in connection with your use
          of the platform or you’re receiving or providing services. You hereby
          release Flexio, its officers, directors, employees, agents,
          representatives, affiliates, and licensors from any liability related
          to any use of our platform or you’re receiving or providing services
          or the conduct or misconduct of a user.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>35.2</strong> Flexio expressly disclaims any liability that
          may arise between users of its platform. Use of our platform and
          receiving or performing services thereunder are entirely at your own
          risk. Flexio currently does not and has no obligations to assess the
          suitability, legality, or ability of any user to perform or complete a
          Job.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>35.3</strong> Under no circumstances will Flexio, its
          affiliates, its licensors, or any of such parties’ agents, employees,
          officers, directors, corporate partners, or participants be liable to
          you, another user or any third party for any indirect, incidental,
          consequential, punitive, special or exemplary damages arising in
          connection with your use of or inability to access or use the platform
          or services, any reliance placed by you on the completeness, accuracy
          or existence of any advertising, or as a result of any relationship or
          transaction between any user, any third-party service provider,
          advertiser or sponsor whose advertising appears on the website or is a
          user of the platform including for personal injury, loss of data,
          revenue, profits, use or other economic advantage, even if all parties
          have been previously advised of the possibility of such damages.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>35.4</strong> You acknowledge and agree that Flexio is only
          willing to provide the platform for your use if you agree to the above
          limitations of our liability to you and third parties.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>35.5</strong> Some states do not allow the exclusion or
          limitation of incidental or consequential damages, so the above
          limitations may not apply to you.
        </p>
      </p>
      <p>
        <strong>36. Waiver and Release</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>36.1</strong> You acknowledge that users providing or
          receiving services using the Flexio platform might not be insured,
          licensed or permitted and you expressly release agree not to hold
          Flexio, its affiliates, its licensors, its partners in promotions,
          sweepstakes or contests, or any of such parties’ agents, employees,
          officers, directors, corporate partners, or participants liable for
          any damage, suits, claims, and/or controversies (collectively,
          “liabilities”) that have arisen or may arise, from or in any way
          relating to a user’s performance or receipt of services hereunder, or
          any other third party introduced to you by the platform whether during
          a Job, or otherwise relating to your or any other party’s use of or
          inability to access or use the platform or services, including without
          limitation any liabilities arising in connection with the conduct, act
          or omission of any user (including without limitation stalking,
          harassment that is sexual or otherwise, acts of physical violence, and
          destruction of personal property), failure of a user to be adequately
          insured, licensed or permitted, any dispute with any user, any
          instruction, advice, act, or service provided by Flexio or its
          affiliates or licensors and any destruction of your information
          whether known or unknown, and whether or such conduct, act or omission
          occurs during your use or access of the platform or app or during the
          performance of a Job. And you expressly waive and release Flexio from
          any and all any liability, claims or damages arising from or in any
          way related to a user’s performance or receipt of services hereunder,
          or any other third party introduced to you by the platform whether
          during a Job, or otherwise.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>36.2</strong> If notwithstanding the foregoing limitations,
          exclusions, waivers and releases, it is determined that Flexio or its
          affiliates, its licensors, its partners in promotions, sweepstakes or
          contests, or any of such parties’ agents, employees, officers,
          directors, corporate partners, or participants are liable for damages,
          in no event will the aggregate liability, whether arising in contract,
          tort, strict liability or otherwise, exceed the total amounts paid by
          or earned by you, as applicable, during the six months prior to the
          time such claim arose.
        </p>
      </p>
      <p>
        <strong>37. Notices</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>37.1</strong> Flexio may give users general notices, whether
          regarding the platform, these T&Cs, or otherwise via the app and/or
          electronic mail to your email address on record in Flexio’s account
          information.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>37.2</strong> Any legal or formal notices, requests or other
          communications required or permitted to be given hereunder shall be in
          writing and shall be delivered by hand, by overnight courier, mailed
          by United States registered or certified mail, return receipt
          requested, postage prepaid, and addressed to the appropriate party at
          its address set forth below.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>37.3</strong> You may give notice to Flexio at any time by
          email at <a href="support@goflexio.com">support@goflexio.com</a> with
          a and/or letter delivered by nationally recognized overnight delivery
          service or first-class postage prepaid mail to Flexio at P.O. Box
          6417, Bayamón, Puerto Rico 00960, addressed to the attention of: VP of
          Development. Flexio will give notice to users at the email and/or
          physical addresses provided by user when forming their account on the
          Flexio platform.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>37.4</strong> Notices shall be deemed to have been given upon
          the expiration of five (5) business days after mailing or posting (if
          sent by first class mail or pre-paid post), if not received sooner, or
          forty-eight (48) hours after sending (if sent by email).
        </p>
      </p>
      <p>
        <strong>38. Export Control</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>38.1</strong> You agree to comply fully with all U.S. and
          foreign export laws and regulations to ensure that none of the
          platform, any technical data related thereto, or any direct product
          thereof is exported or re-exported directly or indirectly in violation
          of, or used for any purposes prohibited by, such laws and regulations.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>38.2</strong> By using the platform, you represent and warrant
          that you are not located in a country that is subject to any U.S.
          Government embargo, or that has been designated by the U.S. Government
          as a “terrorist supporting” country; and you are not listed on any
          U.S. Government list of prohibited or restricted parties.
        </p>
      </p>
      <p>
        <strong>39. Relationship Between Flexio, Drivers and Senders</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>39.1</strong> No joint venture, partnership, employment, or
          agency relationship exists between you and Flexio, any other user, or
          any third-party provider as a result of your forming an account on the
          Flexio platform, agreeing to these T&Cs or using, providing or
          receiving services via the platform.
        </p>
      </p>
      <p>
        <strong>40. Entire Agreement</strong>
        <p style={{ marginLeft: "1em" }}>
          <strong>40.1</strong> Unless Flexio has entered into a separate
          written agreement with a user addressing their access or use of our
          platform, our services and the delivery services, these T&Cs, and all
          agreements and documents referenced herein comprise the entire
          agreement between you and Flexio superseding all prior or
          contemporaneous negotiations, discussions or agreements, whether
          written or oral, between you and Flexio regarding your use of and
          access to the Flexio platform, our services and the delivery services.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>40.2</strong> If any provision of these T&Cs is held to be
          invalid or unenforceable, such provision shall be revised as minimally
          as possible to validate or enforce the same, as well as the original
          intent of these T&Cs, and the remaining provisions shall be enforced
          to the fullest extent under applicable law. The failure of Flexio to
          enforce any right or provision in these T&Cs shall not constitute a
          waiver of such right or provision.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>40.3</strong> Notwithstanding the above, if you have entered
          into a Dispute Resolution and/or Arbitration Agreement and Class
          Action Waiver with Flexio and/or any Flexio affiliate you acknowledge
          and agree such document is part of our agreement governing your access
          to and use of the Flexio platform; provided that any Dispute
          Resolution / Arbitration Agreement and Class Action Waiver you have
          entered into with Flexio and/or any Flexio affiliate is separate and
          distinct from these T&Cs and no update or modification of these T&Cs
          creates a renewed opportunity to opt out of such agreement and/or
          waiver.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>40.4</strong> If you have entered into any separate
          agreement(s) with Flexio and/or any Flexio affiliate addressing your
          access or use of our platform, our services and/or the delivery
          services, you acknowledge that the terms of any such agreement governs
          your access to and use of the Flexio platform, our services and/or the
          delivery services.
        </p>
        <p style={{ marginLeft: "1em" }}>
          <strong>40.5</strong>{" "}
          <strong>Governing Law, Consent to Jurisdiction and Venue.</strong>{" "}
          This Agreement shall be governed by and construed in accordance with
          the laws of the Commonwealth of Puerto Rico without giving effect to
          any choice or conflict of law provision or rule (whether of the
          Commonwealth of Puerto Rico or any other jurisdiction). The parties
          agree that any Action ensuing, under or otherwise related to this
          Agreement will be brought only in the courts of the Commonwealth of
          Puerto Rico sitting in San Juan, Puerto Rico. Each of the parties
          hereto consents to venue of any dispute in San Juan, Puerto Rico and
          further agrees that a non-appealable final judgment in any Action
          shall be conclusive and may be enforced in other jurisdictions by suit
          on the judgment or in any other manner provided by applicable law.
          Each of the Parties hereto irrevocably consents to the service of any
          summons and complaint and any other process in any other Action
          relating to the transactions contemplated hereby, on behalf of itself
          or its property, by the personal delivery of copies of such process to
          such Party. Nothing in this Section 41.5 shall affect the right of any
          party hereto to serve legal process in any other manner permitted by
          applicable law.
        </p>
      </p>
      <div>
        <h2>
          ANNEX I<br /> <small>Carrier Liability Program</small>
        </h2>
        <p>
          <strong>Self-Insurance Program Provided by Flexio</strong>
        </p>
        <p>
          <u>Coverage</u>
          <br />
          Flexio covers up to $100 in declared value for every service or Job,
          as defined above. Declaring a value higher than $100 is available for
          a fee, with a maximum declared value of $10,000.00. It is not
          insurance coverage, but it does offer the opportunity for
          reimbursement through the claims process (“Reimbursement”).
        </p>
          <div>
            <p>
              <strong>Declared Value</strong>
            </p>
            <p>$0-$100</p>
            <p>$100.01-$300.00</p>
            <p>$300.01-$10,000.00</p>
          </div>
          <div>
            <p>
              <strong>Cost</strong>
            </p>
            <p>Free</p>
            <p>$3.00</p>
            <p>$1.15 per $100 of declared value</p>
          </div>
        <p>
          Flexio does not cover any loss with declared values in excess of
          $10,000.01 for any fee. The Sender may choose a third-party insurance
          provider of their choice for the excess declared values.
        </p>
        <p>
          <u>Claims</u>
          <br />
          Reimbursement covers what happens in transit including: extended
          delivery delay, damage to items, or any other mishandling that’s out
          of your business’ control.
          <br />A claim can be done through an e-mail filing in a 3-step
          process:
        </p>
        <ul>
          <li>
            Gather your documents. Keep any evidence and documentation regarding
            your claim until the process is complete. Claims must include the
            Job’s Job Number, Job’s Job Number, documentation proving item
            value, and evidence of damage (if applicable).
          </li>
          <li>File your claim via electronic mail. </li>
          <li>
            Flexio may decide to pay a claim in full or in part. If your claim
            is approved, payments are processed within 7-10 business days.
          </li>
        </ul>
      </div>

  
          </div>
        </div>
      </div>
    );
};

export default ClienttermsPage;
